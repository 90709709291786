export function darken(color: string, amount: number) {
  const { r, g, b, usePound } = hexToRgb(color);

  return rgbToHex(
    applyGradient(r, -amount),
    applyGradient(g, -amount),
    applyGradient(b, -amount),
    usePound,
  );
}

function applyGradient(colorValue: number, amount: number) {
  const result = (colorValue * (100 + amount * 100)) / 100;
  if (result > 255) return 255;
  else if (result < 0) return 0;
  return Math.round(result);
}

export function isColor(strColor: string) {
  return strColor.startsWith('#') || strColor.match(/^(rgb|hsl)/);
}
export const lighten = (color: string, amount: number) => revert(darken(revert(color), amount));

export const alternate = (color: string) =>
  isColorDark(color) ? lighten(color, 0.1) : darken(color, 0.1);

export function isColorDark(hex: string): boolean {
  const { r, g, b } = hexToRgb(hex);
  return r * 0.299 + g * 0.587 + b * 0.114 < 186;
}

function hexToRgb(color: string) {
  let usePound = false;
  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  return {
    r: (num >> 16) & 0xff,
    g: (num >> 8) & 0xff,
    b: num & 0xff,
    usePound,
  };
}

function revert(color: string) {
  const { r, g, b, usePound } = hexToRgb(color);
  return rgbToHex(255 - r, 255 - g, 255 - b, usePound);
}

const rgbToHex = (r: number, g: number, b: number, usePound = true) =>
  (usePound ? '#' : '') + (b | (g << 8) | (r << 16)).toString(16).padStart(6, '0');

export function isHex(txt: string) {
  if (txt.length > 9) return false;
  const value = txt.startsWith('#') ? txt.slice(1) : txt;
  const regex = /[0-9A-Fa-f]{6}/g;
  return regex.test(value);
}

export function isImageDark(imgSrc: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.src = imgSrc;
    img.crossOrigin = 'Anonymous';
    img.style.display = 'none';
    document.body.appendChild(img);

    let colorSum = 0;

    img.onerror = (e) => reject(new Error(`Failed to load image: ${e}`));

    img.onload = () => {
      const { width, height } = img;
      const canvas = document.createElement('canvas');

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return resolve(false);

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const { data } = imageData;
      let r, g, b, avg;

      for (let x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      const brightness = Math.floor(colorSum / (width * height));
      document.body.removeChild(img);

      resolve(brightness <= 127);
    };
  });
}
